/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from "react";
import {
  Container,
  Box,
  chakra,
  Flex,
  Link,
  Image,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
const articles = [
  {
    title: "Voltas Limited, Vadodara (30TR AC Laboratory)",
    link: "#",
    description:
      "",
    logo: "https://via.placeholder.com/100x50?text=Voltas", // Replace with actual logo URL
  },
  {
    title: "Haier Appliances, Noida, AC & Refrigerator Testing Laboratory",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Haier", // Replace with actual logo URL
  },
  {
    title: "Haier Appliances, Noida, Washing Machine Lab. (NABL & Lab setup)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Haier",
  },
  {
    title: "Haier Appliances (AC&REF), Pune",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Haier",
  },
  {
    title: "Haier Appliances, Gr Noida, NABL Scope Enhancement",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Haier",
  },
  {
    title: "Haier Appliances, Pune (Commercial AC Lab, NABL)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Haier",
  },
  {
    title: "Samsung, Chennai AC and REF Labs",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Samsung", // Replace with actual logo URL
  },
  {
    title: "LG Electronics, REF Lab, Pune",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=LG", // Replace with actual logo URL
  },
  {
    title: "PwC, Gurugram",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=PwC", // Replace with actual logo URL
  },
  {
    title: "Voltas Limited, Vadodara (BIS-QCO)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Voltas", // Replace with actual logo URL
  },
  {
    title: "Voltas-Beko, Sanand, Ahmedabad",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Voltas", // Replace with actual logo URL
  },
  {
    title: "Voltas Ltd., Vadodara (600 TR Chiller Labs)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Voltas", // Replace with actual logo URL
  },
  {
    title: "EPACK Durables, Dehradun",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=EPACK", // Replace with actual logo URL
  },
  {
    title: "Ahmedabad Municipal Corporation, Ahmedabad",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=AMC", // Replace with actual logo URL
  },
  {
    title: "Gemcare Appliances Pvt. Ltd., Halol, Gujarat (NABL & BIS)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Gemcare", // Replace with actual logo URL
  },
  {
    title: "Amber Enterprises India Ltd., Jhajjar",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Amber", // Replace with actual logo URL
  },
  {
    title: "Carrier Midea India Ltd., Supa, Ahmednagar",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Carrier", // Replace with actual logo URL
  },
  {
    title: "AECOM, Gurugram",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=AECOM", // Replace with actual logo URL
  },
  {
    title: "GACL, Vadodara",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=GACL", // Replace with actual logo URL
  },
  {
    title: "SKP Projects, Vadodara",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=SKP", // Replace with actual logo URL
  },
  {
    title: "PG Technoplast Pvt. Ltd., Supa, Maharashtra",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=PG Technoplast", // Replace with actual logo URL
  },
  {
    title: "National Test House, Mumbai",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=NTH", // Replace with actual logo URL
  },
  {
    title: "Amber Enterprises, Sri City",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Amber", // Replace with actual logo URL
  },
  {
    title: "Johnson Controls (India) Pvt. Ltd., Pune",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Johnson Controls", // Replace with actual logo URL
  },
  {
    title: "Softhard Automation Pvt. Ltd., Pune",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Softhard", // Replace with actual logo URL
  },
  {
    title: "Kirloskar Chillers, Pune (NABL Accreditation)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Kirloskar", // Replace with actual logo URL
  },
  {
    title: "Dixon Electro Manf Pvt. Ltd., Gr Noida (REF Plant, NABL & BIS)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Dixon", // Replace with actual logo URL
  },
  {
    title: "Swegon Blue Box Pvt. Ltd., Thane (Chiller Labs, NABL)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Swegon", // Replace with actual logo URL
  },
  {
    title: "ACE Test House, Delhi (Washing Machine Lab setup & NABL)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=ACE", // Replace with actual logo URL
  },
];

const Articles = () => {
  // const bg = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("gray.100", "gray.700");
  const titleColor = useColorModeValue("blue.600", "blue.300");
  const buttonBg = useColorModeValue("gray.100", "gray.700");
  const buttonHoverBg = useColorModeValue("gray.200", "gray.600");

  const [currentSlide, setCurrentSlide] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragOffset, setDragOffset] = useState(0);
  const [autoplay, setAutoplay] = useState(true);

  const slidesCount = articles.length;

  const prevSlide = useCallback(() => {
    setCurrentSlide((s) => (s > 0 ? s - 1 : s));
    setAutoplay(false);
    setTimeout(() => setAutoplay(true), 2000);
  }, []);

  const nextSlide = useCallback(() => {
    setCurrentSlide((s) => (s < slidesCount - 1 ? s + 1 : s));
    setAutoplay(false);
    setTimeout(() => setAutoplay(true), 2000);
  }, [slidesCount]);

  const handleMouseDown = useCallback((e) => {
    setDragging(true);
    setDragStartX(e.clientX);
    setAutoplay(false);
    e.preventDefault();
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      if (dragging) {
        const diffX = e.clientX - dragStartX;
        setDragOffset(diffX);
        e.preventDefault();
      }
    },
    [dragging, dragStartX]
  );

  const handleMouseUp = useCallback(() => {
    if (dragging) {
      setDragging(false);
      if (Math.abs(dragOffset) > 100) {
        if (dragOffset > 0 && currentSlide > 0) {
          prevSlide();
        } else if (dragOffset < 0 && currentSlide < slidesCount - 1) {
          nextSlide();
        }
      }
      setDragOffset(0);
      setTimeout(() => setAutoplay(true), 2000);
    }
  }, [dragging, dragOffset, prevSlide, nextSlide, currentSlide, slidesCount]);

  const handleMouseLeave = useCallback(() => {
    handleMouseUp();
    setTimeout(() => setAutoplay(true), 2000);
  }, [handleMouseUp]);

  const slideOffset =
    currentSlide === 0 && dragOffset > 0
      ? 0
      : currentSlide === slidesCount - 1 && dragOffset < 0
      ? 0
      : dragOffset;

  const carouselStyle = {
    transition: dragging ? "none" : "all .5s",
    ml: `calc(-${currentSlide * 300}px + ${slideOffset}px)`,
  };

  useEffect(() => {
    let intervalId;
    if (autoplay) {
      intervalId = setInterval(() => {
        setCurrentSlide((s) => {
          if (s >= slidesCount - 1) {
            return 0;
          }
          return s + 1;
        });
      }, 3000);
    }
    return () => clearInterval(intervalId);
  }, [autoplay, slidesCount]);

  return (
    <Container maxW="7xl" p={{ base: 5, md: 10 }} id="customer">
      <Flex justifyContent="left" mb={6}>
        <chakra.h3 fontSize="2xl" fontWeight="bold" color={titleColor}>
          Our Esteemed Customers
        </chakra.h3>
      </Flex>
      <Box position="relative">
        <Flex
          w="full"
          overflow="hidden"
          pos="relative"
          onMouseLeave={handleMouseLeave}
        >
          <Flex
            w="full"
            flexWrap="nowrap"
            {...carouselStyle}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onMouseDown={handleMouseDown}
          >
            {articles.map((article, index) => (
              <Box
                key={index}
                w="300px"
                h="200px"
                mr={4}
                flexShrink={0}
              >
                <Box
                  as={motion.div}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.5,
                    delay: index * 0.2,
                    ease: "easeInOut",
                  }}
                  whileHover={{
                    scale: 1.02,
                    boxShadow: "xl",
                    transition: { duration: 0.3 },
                  }}
                >
                  <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    p={4}
                    bg="gray.100"
                    borderRadius="md"
                    _hover={{
                      bg: hoverBg,
                      transform: "translateY(-2px)",
                      transition: "all 0.3s ease",
                    }}
                  >
                    <Image
                      src={article.logo}
                      alt={`${article.title} logo`}
                      maxW="120px"
                      h="auto"
                      mb={4}
                    />
                    <Box>
                      <chakra.h3
                        as={Link}
                        href={article.link}
                        isExternal
                        fontWeight="bold"
                        fontSize={{ base: "md", sm: "lg" }}
                        color={titleColor}
                        _hover={{ textDecoration: "underline" }}
                      >
                        {article.title}
                      </chakra.h3>
                      {article.description && (
                        <chakra.p fontSize="sm" mt={2}>
                          {article.description}
                        </chakra.p>
                      )}
                    </Box>
                  </Flex>
                </Box>
              </Box>
            ))}
          </Flex>
        </Flex>

        {/* Navigation Buttons */}
        {currentSlide > 0 && (
          <IconButton
            aria-label="Previous slide"
            icon={<ChevronLeftIcon />}
            position="absolute"
            left="-16px"
            top="50%"
            transform="translateY(-50%)"
            onClick={prevSlide}
            bg={buttonBg}
            _hover={{ bg: buttonHoverBg }}
            zIndex={2}
          />
        )}
        {currentSlide < slidesCount - 1 && (
          <IconButton
            aria-label="Next slide"
            icon={<ChevronRightIcon />}
            position="absolute"
            right="-16px"
            top="50%"
            transform="translateY(-50%)"
            onClick={nextSlide}
            bg={buttonBg}
            _hover={{ bg: buttonHoverBg }}
            zIndex={2}
          />
        )}
      </Box>
    </Container>
  );
};

export default Articles;
// /* eslint-disable react-hooks/rules-of-hooks */
// import { Fragment } from "react"
// import {
//   Container,
//   Box,
//   chakra,
//   Flex,
//   VStack,
//   Grid,
//   Divider,
//   Link,
//   useColorModeValue
// } from "@chakra-ui/react"

// const articles = [
//   {
//     title: "Ahmedabad Municipal Corporation, Ahmedabad    ",
//     link:
//       "#",
//     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi quod dolorum eaque vol'

//   },
//   {
//     title: "Voltas Limited, Vadodara (30TR AC Laboratory)",
//     link:
//       "#",

//   },
//   {
//     title: "Haier Appliances, Noida, AC Testing Laboratory",
//     link: "#",

//   },
//   {
//     title: "Samsung, Chennai AC and REF Labs",
//     link:
//       "#",

//   },
//   {
//     title: "LG Electronics, REF Lab, Pune",
//     link:
//       "#",

//   }
// ]

// const Articles = () => {
//   return (
//     <Container maxW="5xl" p={{ base: 5, md: 10 }}>
//       <Flex justifyContent="left" mb={3}>
//         <chakra.h3 fontSize="2xl" fontWeight="bold" textAlign="center">
//           Our Esteemed Customers
//         </chakra.h3>
//       </Flex>
//       <VStack
//         border="1px solid"
//         borderColor="gray.400"
//         rounded="md"
//         overflow="hidden"
//         spacing={0}
//       >
//         {articles.map((article, index) => (
//           <Fragment key={index}>
//             <Grid
//               templateRows={{ base: "auto auto", md: "auto" }}
//               w="100%"
//               templateColumns={{ base: "unset", md: "4fr 2fr 2fr" }}
//               p={{ base: 2, sm: 4 }}
//               gap={3}
//               alignItems="center"
//               _hover={{ bg: useColorModeValue("gray.200", "gray.700") }}
//             >
//               <Box gridColumnEnd={{ base: "span 2", md: "unset" }}>
//                 <chakra.h3
//                   as={Link}
//                   href={article.link}
//                   isExternal
//                   fontWeight="bold"
//                   fontSize="lg"
//                 >
//                   {article.title}
//                 </chakra.h3>

//               </Box>

//             </Grid>
//             {articles.length - 1 !== index && <Divider m={0} />}
//           </Fragment>
//         ))}
//       </VStack>
//     </Container>
//   )
// }
// export default Articles;
