import React, { useState } from 'react';
import { Flex, Button, Image, Link, Icon, HStack, Stack, Box, IconButton, useDisclosure, Center } from '@chakra-ui/react';
import { HiMenu, HiX } from 'react-icons/hi';
import logo from './logo3s.png';

const Links = ['Home', 'Payment', 'Customer', 'Service', 'Contact Us'];

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Box
      bg="white"
      px={4}
      py={2}
      boxShadow="md"
      zIndex={1}
      position="sticky"
      top="0"
    >
      <Flex h={16} alignItems={'center'} justifyContent="space-between">
        <IconButton
          size={'sm'}
          icon={isMobileMenuOpen ? <HiX fontSize="24px" alignItems={'center'}/> : <HiMenu fontSize="24px" alignItems={'center'} />}
          aria-label={'Open Menu'}
          display={{ md: 'none' }}
          onClick={handleMobileMenuToggle}
        />
        <HStack spacing={8} alignItems={'center'}>
          <Image
            h={{ base: "50px", md: "70px" }}
            w={{ base: "80px", md: "110px" }}
            objectFit="wrap"
            src={logo}
            alt="3S"
          />
        </HStack>
        <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
          {Links.map((link) => (
            <Link key={link} href={`/#${link.toLowerCase().replace(' ', '-')}`}>
              <Button fontSize="lg">{link}</Button>
            </Link>
          ))}
        </HStack>
      </Flex>

      {isMobileMenuOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as={'nav'} spacing={4}>
            {Links.map((link) => (
              <Link key={link} href={`/#${link.toLowerCase().replace(' ', '-')}`}>
                <Button fontSize="lg">{link}</Button>
              </Link>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default Navbar;