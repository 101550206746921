import React from 'react';
import {
  Flex,
  Text,
  useMediaQuery,
  Icon,
  Heading,
  Stack,
  Box,
} from '@chakra-ui/react';
import {
  FaFlask,
  FaCertificate,
  FaLightbulb,
  FaCheckCircle,
  FaBook,
  FaBolt,
  FaChartLine,
} from 'react-icons/fa';
import { motion } from 'framer-motion';

const Services = () => {
  const [isLargerThan48] = useMediaQuery('(min-width: 48em)');

  const array = [
    {
      id: 1,
      text: 'Consultancy for NABL-ISO/IEC 17025-2017',
      icon: FaCertificate,
    },
    {
      id: 2,
      text: 'Consultancy for BIS-QCO Certification.',
      icon: FaCheckCircle,
    },
    {
      id: 3,
      text: 'TRG on ISO/IEC 17025, MU and NABL Policy.',
      icon: FaBook,
    },
    {
      id: 4,
      text: 'Laboratory Setup for AC, Refrigerator, Washing Machine and Other Household Appliances (Meeting BEE and NABL Requirements)',
      icon: FaFlask,
    },
    {
      id: 5,
      text: 'Detailed Energy Audit (Electrical & Thermal)',
      icon: FaBolt,
    },
    {
      id: 6,
      text: '3rd Party Measurement & Verification for Energy Saving Projects',
      icon: FaChartLine,
    },
    {
      id: 7, // Updated id to be unique
      text: 'Training on Energy Management/Energy Conservation.',
      icon: FaLightbulb,
    },
  ];

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Stack id="service">
      <Heading px="6" textColor="blue.600">
        Services We Provide
      </Heading>
      <Flex
        minH="80vh"
        alignItems="center"
        justifyContent="space-between"
        w="full"
        py="21"
        px={isLargerThan48 ? '16' : '6'}
        pt="2"
        flexWrap="wrap"
        flexDirection={isLargerThan48 ? 'row' : 'column'}
      >
        {array.map((arr, index) => (
          <Box
          as={motion.div}
          key={arr.id}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }} // Adjust amount for sensitivity
          variants={variants}
          transition={{
            duration: 9.9,
            delay: index * 0.9, 
            ease: 'easeInOut',
          }}
          width={isLargerThan48 ? '32%' : 'full'}
          maxWidth="100%" // Add max-width to prevent overflow
          Width={150}
          height={180}
          bg="blue.100"
          p="4" // Adjust padding
          alignItems="center"
          justifyContent="center"
          borderRadius="md"
          textAlign="center"
          mb={isLargerThan48 ? '2' : '4'}
          border="1px solid #C4DDFe"
          _hover={{ transform: 'scale(1.05)', shadow: 'xl' }} // Add hover effect
        >
          <Icon as={arr.icon} boxSize={14} color="green.600" mb="2" /> 
          <Text>{arr.text}</Text>
        </Box>
        ))}
      </Flex>
    </Stack>
  );
};

export default Services;
