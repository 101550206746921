import React from 'react'
import Navbar from './Components/Navbar'
import HeroSec from './Components/HeroSec'
import Services from './Components/Services'
import CustomerSec from './Components/CustomerSec'
import Footer from './Components/Footer'
import ProfileForm from './Components/Contactus'
import ProfileSection from './Components/Profile'
const Home = () => {
  return (
    <div>
      <Navbar />
      <HeroSec />
      <ProfileSection />
      <Services />
      <CustomerSec />
      <ProfileForm />
     
      <Footer />
    </div>
  )
}

export default Home